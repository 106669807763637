/* this file will be extracted to main dist folder and is imported in index.html */
/* This file is for setting global styles  */
/** FONT SIZES
 --------------------------------------------------------- */
/** FONT WEIGHTS
  --------------------------------------------------------- */
/* BASE  */
.btn {
  height: 50px;
  border: none; }
  .btn--secondary {
    background: #f0f0f0; }
  .btn--primary {
    background: #007AFF; }
  .btn--outline {
    border-radius: 7px;
    border: 1px solid white;
    background: transparent;
    line-height: 50px;
    padding: 0 2rem;
    color: white;
    text-decoration: none; }
    .btn--outline::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20; }
  .btn--delete, .btn--edit {
    cursor: pointer;
    color: #bbb;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    margin-left: 0.5rem; }
  .btn--delete:hover {
    color: #f44336;
    background: rgba(244, 67, 54, 0.1); }
  .btn--delete:active {
    background: rgba(244, 67, 54, 0.2);
    color: #f44336; }
  .btn--edit:hover {
    color: #007AFF;
    background: rgba(0, 122, 255, 0.1); }
  .btn--edit:active {
    background: rgba(0, 122, 255, 0.2);
    color: #007AFF; }
  .btn__icon + .btn__text {
    margin-left: 0.5rem; }
  .btn-wrap {
    display: flex;
    margin: 0 -0.5rem;
    width: calc(100% + 1rem); }
    .btn-wrap .btn,
    .btn-wrap button,
    .btn-wrap button.mat-raised-button {
      margin: 0.5rem;
      width: 100%; }

.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button {
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
  border-radius: 4px !important; }

button {
  display: flex;
  align-items: center; }
  button fa-icon {
    margin-right: 0.5rem;
    align-items: center; }

body {
  margin: 0;
  background: rgba(242, 245, 248, 0.5);
  color: #0a0a0a;
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal; }

.container {
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px; }
  @media screen and (max-width: 600px) {
    .container {
      padding-right: 10px;
      padding-left: 10px; } }

main {
  min-height: calc(100vh - 365px); }

a {
  color: #007AFF; }

p {
  line-height: 25px; }

::selection {
  background: #007AFF;
  color: white; }

::-moz-selection {
  background: #007AFF;
  color: white; }

[hidden] {
  display: none !important; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

@-webkit-keyframes fadeInFromTop {
  from {
    opacity: 0;
    top: -10px; }
  to {
    opacity: 1;
    top: 0; } }

@-moz-keyframes fadeInFromTop {
  from {
    opacity: 0;
    top: -10px; }
  to {
    opacity: 1;
    top: 0; } }

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    top: -10px; }
  to {
    opacity: 1;
    top: 0; } }

@-webkit-keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.95); }
  50% {
    transform: scale(1.01); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@-moz-keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.95); }
  50% {
    transform: scale(1.01); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.95); }
  50% {
    transform: scale(1.01); }
  100% {
    opacity: 1;
    transform: scale(1); } }

.fade-in {
  opacity: 0;
  /* make things invisible upon start */
  position: relative; }
  .fade-in.fromTop {
    -webkit-animation: fadeInFromTop ease-in 1;
    /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeInFromTop ease-in 1;
    animation: fadeInFromTop ease-in 1;
    -webkit-animation-fill-mode: forwards;
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: .2s;
    -moz-animation-duration: .2s;
    animation-duration: .2s; }
  .fade-in.zoom {
    -webkit-animation: fadeInZoom ease-out 1;
    /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeInZoom ease-out 1;
    animation: fadeInZoom ease-out 1;
    -webkit-animation-fill-mode: forwards;
    /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    animation-duration: .5s; }
  .fade-in.one {
    -webkit-animation-delay: 0.4s;
    -moz-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  .fade-in.two {
    -webkit-animation-delay: .6s;
    -moz-animation-delay: .6s;
    animation-delay: .6s; }
  .fade-in.three {
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-delay: 1.5s; }

.row {
  margin-left: -15px;
  width: calc(100% + 30px);
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap; }

.col-6 {
  width: 50%;
  padding: 0 15px;
  margin: 0; }
  @media screen and (max-width: 600px) {
    .col-6 {
      width: 100%; } }

.col-12 {
  width: 100%;
  padding: 0 15px;
  margin: 0; }

.col-4 {
  width: calc(100%/3);
  padding: 0 15px;
  margin: 0; }
  @media screen and (max-width: 600px) {
    .col-4 {
      width: 100%; } }

.page-title__primary {
  padding-right: 10px; }

.page-title__secondary {
  font-weight: 300;
  font-size: 0.8em; }

.page-subtitle {
  line-height: 30px;
  padding: 20px 0;
  margin-bottom: 0; }

.page-info-blocks {
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  justify-content: space-between; }
  .page-info-blocks--two-column .page-info-blocks__block {
    width: calc(100%/2); }
  .page-info-blocks__block {
    padding: 20px 30px;
    width: calc(100%/3); }
  .page-info-blocks__title {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
    padding-bottom: 10px;
    display: flex; }
    .page-info-blocks__title-icon {
      color: #0746B1;
      margin-right: 12px; }
      .page-info-blocks__title-icon img {
        max-width: 2rem; }
  .page-info-blocks__content {
    font-size: 15px;
    line-height: 25px;
    color: #2E2E2E;
    font-weight: 300; }
    .page-info-blocks__content p {
      margin: 0;
      color: #2E2E2E; }
  @media screen and (max-width: 600px) {
    .page-info-blocks {
      flex-wrap: wrap; }
      .page-info-blocks .page-info-blocks__block {
        width: 100%;
        text-align: start; }
      .page-info-blocks .page-info-blocks__title {
        justify-content: flex-start; } }

.kamp .page-info-blocks__title-icon {
  color: #6EC761; }

.doewaweekend .page-info-blocks__title-icon {
  color: #007AFF; }

.gmaps-card {
  margin-bottom: 30px;
  margin-top: 10px; }
  .gmaps-card iframe {
    width: 100%;
    height: 300px; }
  @media screen and (max-width: 600px) {
    .gmaps-card iframe {
      height: 200px; } }

mat-card.mat-card {
  margin-bottom: 20px;
  width: 100%;
  padding: 1.75rem;
  border-radius: 7px; }
  @media screen and (max-width: 600px) {
    mat-card.mat-card {
      padding: 20px; } }
  mat-card.mat-card--sloerojo-wknd {
    background: #0746B1;
    color: #fff; }
    mat-card.mat-card--sloerojo-wknd p {
      color: #fff; }
  mat-card.mat-card--doewa-wknd {
    background: #007AFF;
    color: #fff; }
    mat-card.mat-card--doewa-wknd p {
      color: #fff; }
  mat-card.mat-card--kamp {
    background: #6EC761;
    color: #fff; }
    mat-card.mat-card--kamp * {
      color: #fff; }

.sloerojo-wknd-wrap .count-child__wrap .mat-form-field-wrapper {
  padding-bottom: 1.25em; }

.card__subtitle {
  font-weight: 300; }

.card--top {
  margin-top: -50px; }
  .card--top .page-title {
    font-weight: 600;
    margin-top: 0.25rem; }
    @media screen and (max-width: 600px) {
      .card--top .page-title {
        display: flex;
        margin-bottom: 2rem;
        flex-direction: column; } }
  .card--top .page-subtitle {
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 300;
    margin-top: -10px;
    font-size: 20px;
    color: #4B5057;
    padding-top: 0; }

button.mat-raised-button {
  line-height: 45px;
  padding: 0 25px;
  margin: 15px 0; }
  @media screen and (max-width: 600px) {
    button.mat-raised-button.submit-form {
      width: 100%; } }

button {
  border: none;
  -webkit-appearance: none;
  background: none; }
  button .icon + * {
    margin-left: 0.5rem; }
  button .icon {
    width: 1rem;
    height: 1rem; }

.sub-text {
  margin-top: 4px;
  font-size: 18px;
  color: #777; }

.privacy__content .address {
  display: flex; }
  .privacy__content .address p {
    width: 50%; }

.privacy__content h3 {
  font-weight: 600; }

.success-img {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 7px; }
  @media screen and (max-width: 600px) {
    .success-img {
      width: 90%; } }

.red {
  color: red; }

@media screen and (min-width: 1280px) {
  .card--top .page-title {
    font-size: 2.75rem;
    margin-bottom: 1rem; } }

hr {
  opacity: 0.3;
  margin: 1rem 0; }

p {
  color: #4B5057; }

strong {
  font-weight: 600; }

.hs-2 {
  margin-top: 3rem;
  font-weight: normal;
  font-size: 24px; }

.hs-3 {
  font-size: 20px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-weight: 600; }
  .hs-3 .icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem; }

.m-0 {
  margin: 0; }

.truncate, .db-card__title, .db-card__amount {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

@media print {
  @page {
    margin: 0.5cm; }
  app-full-layout {
    display: none; }
  .modal__backdrop {
    display: none; }
  .calendar {
    width: 100% !important; }
    .calendar .start-date, .calendar .end-date {
      padding: 0.5rem 1rem !important; }
      .calendar .start-date__day, .calendar .end-date__day {
        font-size: 1rem !important; }
      .calendar .start-date__month, .calendar .end-date__month {
        font-size: 0.75rem !important; }
  .modal {
    position: static !important;
    justify-content: flex-start;
    align-items: flex-start; }
    .modal__inner {
      width: 100%; }
    .modal__backdrop {
      display: none !important; }
    .modal .mat-card__body {
      max-height: initial !important; }
  button {
    display: none !important; }
  .calendar .calendar-row__title {
    font-size: 18px; }
  .calendar .calendar-row__subtitle {
    font-size: 14px; }
  .calendar .start-date, .calendar .end-date {
    padding: 0.5rem 1rem; }
    .calendar .start-date__day, .calendar .end-date__day {
      font-size: 1.5rem; }
    .calendar .start-date__month, .calendar .end-date__month {
      font-size: 16px; }
  .calendar .start-date {
    padding-left: 0; }
  .calendar .mat-card {
    box-shadow: none !important;
    max-width: initial;
    border-radius: none !important;
    background: none !important;
    overflow: visible; }
    .calendar .mat-card__subtitle {
      margin: 4px; }
    .calendar .mat-card__header {
      justify-content: flex-start;
      margin-bottom: 1rem; }
    .calendar .mat-card__body {
      max-height: initial; }
    .calendar .mat-card__close {
      display: none; }
    .calendar .mat-card__logo {
      display: block; } }

.alert {
  margin-bottom: 0.5rem;
  font-size: 15px;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 20px; }
  .alert--error {
    background: rgba(255, 0, 0, 0.8);
    color: white; }

/* COMPONENTS  */
.header {
  height: 300px;
  overflow: hidden;
  position: relative; }
  .header .logo {
    width: 150px;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .header .logo:focus {
      outline: none; }
    .header .logo:hover {
      transform: scale(1.05); }
    @media screen and (max-width: 600px) {
      .header .logo {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1rem;
        position: relative;
        left: -13px; } }
  .header__subtitle {
    font-weight: 300;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0; }
  .header__cta {
    margin-top: 1rem; }
  .header--bakterwatvan, .header--grilterwatvan {
    height: 500px; }
    @media screen and (max-width: 600px) {
      .header--bakterwatvan .logo, .header--grilterwatvan .logo {
        display: none; } }
  .header--grilterwatvan .mat-raised-button.mat-primary {
    background: #34424C !important; }
    .header--grilterwatvan .mat-raised-button.mat-primary:hover {
      background: #2a353d !important; }
  .header__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .header__content .container {
      height: 100%; }
    .header__content-grid {
      display: grid;
      place-items: center;
      height: 100%; }
      .header__content-grid-inner {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
  .header__page-logo {
    max-width: 350px;
    width: 100%;
    pointer-events: auto; }
    .header__page-logo img {
      width: 100%;
      height: auto; }
    @media screen and (max-width: 600px) {
      .header__page-logo {
        max-width: 250px; } }

@-webkit-keyframes unBlur {
  from {
    -webkit-filter: blur(15px); }
  to {
    -webkit-filter: blur(0); } }

@-moz-keyframes unBlur {
  from {
    -moz-filter: blur(15px); }
  to {
    -moz-filter: blur(0); } }

@keyframes unBlur {
  from {
    filter: blur(15px); }
  to {
    filter: blur(0); } }

.header__img {
  display: block;
  width: calc(100% + 40px);
  height: 350px;
  object-fit: cover;
  background: rgba(0, 0, 0, 0.04);
  margin: -5px -20px;
  -webkit-animation: unBlur ease-in 1;
  -moz-animation: unBlur ease-in 1;
  animation: unBlur ease-in 1;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s; }
  @media screen and (max-width: 600px) {
    .header__img {
      height: 300px; } }

.header__img-wrap {
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-position: 50%; }

.header__top {
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 70px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 100%); }
  .header__top-inner {
    position: relative; }

.footer {
  width: 100%;
  height: 130px;
  margin-top: -3rem;
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }
  .footer__title {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center; }
    .footer__title-icon {
      width: 1.25rem;
      color: #fff;
      margin-right: 0.75rem; }
      .footer__title-icon svg {
        color: red; }
  .footer__top {
    padding-top: 1rem;
    background: #1a1b1e; }
    .footer__top .footer__inner {
      color: #fff;
      padding: 3rem 0;
      display: flex;
      gap: 1rem; }
      @media screen and (max-width: 960px) {
        .footer__top .footer__inner {
          flex-direction: column; } }
    .footer__top .footer__section {
      width: 50%; }
      @media screen and (max-width: 960px) {
        .footer__top .footer__section {
          width: 100%; } }
  .footer__bottom {
    background: #1a1b1e;
    border-top: 1px solid #0a0a0a;
    position: relative; }
    .footer__bottom::after {
      content: '';
      background: #434651;
      width: 100%;
      top: 1px;
      position: absolute;
      left: 0;
      height: 1px; }
    .footer__bottom .footer__inner {
      display: flex;
      justify-content: flex-start; }
  .footer__logo {
    width: 100px; }
  .footer__inner {
    position: relative; }
  .footer .login-wrap {
    color: rgba(255, 255, 255, 0.2);
    padding: 20px;
    cursor: pointer;
    margin-left: -20px; }
    .footer .login-wrap:hover {
      color: rgba(255, 255, 255, 0.3); }
  .footer .social {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .footer .social__title {
      margin: 0.5rem 0; }
    .footer .social__link {
      display: flex;
      border-radius: 0.5rem;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      background: #2E2E2E;
      margin: 0.25rem 0;
      padding: 0.5rem 1rem;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
      border: 1px solid rgba(67, 70, 81, 0.7); }
      .footer .social__link:hover {
        background: #4c505d;
        text-decoration: none; }
    .footer .social__icon {
      color: #fff;
      display: flex;
      margin-right: 0.5rem; }
    .footer .social__icons {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: auto auto; }
  .footer .payment-icons {
    padding-top: 0.25rem; }

.simple-header {
  height: 50px;
  background: white; }
  .simple-header__top {
    height: 100%; }
    .simple-header__top-inner {
      height: 100%;
      display: flex; }
  .simple-header .logo {
    width: calc(100% - 50px);
    display: flex;
    justify-content: center; }
    .simple-header .logo img {
      height: 42px;
      margin-top: 11px; }
  .simple-header .logout {
    width: 50px;
    display: flex;
    justify-content: center;
    border-left: 1px solid #f2f5f8; }
    .simple-header .logout__icon {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      cursor: pointer; }

mat-form-field {
  margin-left: 10px;
  margin-right: 10px; }

.container--signup .mat-card {
  padding: 8px 24px !important; }
  @media screen and (max-width: 600px) {
    .container--signup .mat-card {
      padding: 8px 1rem !important; } }

.container--signup .card--top {
  padding: 1.75rem !important; }

.gender-wrap {
  margin-bottom: 1rem; }
  .gender-wrap mat-radio-button:first-child {
    margin-right: 20px; }
  .gender-wrap .label {
    margin-top: 0;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0.5rem; }

.mat-slide-toggle {
  height: auto !important; }
  .mat-slide-toggle .mat-slide-toggle-content {
    font-size: 16px;
    color: #444;
    white-space: normal !important; }

.mat-checkbox-layout {
  white-space: normal !important; }
  .mat-checkbox-layout .mat-checkbox-inner-container {
    vertical-align: top; }
  .mat-checkbox-layout .mat-checkbox-label {
    padding-left: 5px; }

.healt-questions .row {
  padding: 20px 0; }
  .healt-questions .row--hidden {
    padding-top: 0; }

.payment__wrap h2 {
  font-weight: 400;
  font-size: 1.5rem; }

.payment__card {
  padding: 16px 20px;
  background: rgba(0, 122, 255, 0.1);
  border-radius: 5px; }

.payment__instructions {
  font-size: 1.25rem; }

.count-child__wrap {
  display: flex; }
  .count-child__wrap p {
    margin-top: 1.5rem; }
  .count-child__wrap .mat-form-field-wrapper {
    padding-bottom: 0; }
  @media screen and (max-width: 600px) {
    .count-child__wrap {
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 25px; }
      .count-child__wrap p {
        margin: 0; }
      .count-child__wrap .mat-form-field {
        margin-left: 0;
        margin-right: 0; } }

.count-child__section .sub-text {
  margin-bottom: 0; }

.signup-section {
  padding: 24px 0; }
  .signup-section--border-bottom {
    border-bottom: 1px dashed #e9e9e9; }
  .signup-section--border-top {
    border-top: 1px dashed #e9e9e9; }
  .signup-section.contact {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 0;
    position: relative; }
    .signup-section.contact:first-child {
      border-right: 1px dashed #e9e9e9; }
    .signup-section.contact .contact__close {
      position: absolute;
      background: none;
      color: #bbb;
      border: none;
      padding: 1rem;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      -webkit-appearance: none;
      top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0; }
      .signup-section.contact .contact__close:hover {
        color: #a2a2a2; }
    @media screen and (max-width: 600px) {
      .signup-section.contact {
        padding-left: 1rem;
        padding-right: 1rem; }
        .signup-section.contact:first-child {
          border-right: none;
          border-bottom: 1px dashed #e9e9e9; } }
  .signup-section.add-contact {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 600px) {
      .signup-section.add-contact {
        padding-left: 1rem;
        padding-right: 1rem; } }
    .signup-section.add-contact .mat-button-wrapper {
      display: flex;
      padding: 0.75rem 0; }
      .signup-section.add-contact .mat-button-wrapper span {
        white-space: normal;
        line-height: 1.25rem !important; }

.home__cards {
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  flex-direction: column; }
  .home__cards-inner {
    display: flex;
    flex-wrap: wrap; }

.home__card--noitems {
  padding: 15px; }
  .home__card--noitems h2 {
    margin-bottom: 0; }

.home__card-wrap {
  width: 50%; }
  .home__card-wrap:empty {
    display: none; }

@media screen and (max-width: 600px) {
  .home__card-wrap {
    width: 100%; } }

.home .card {
  margin: 15px;
  display: flex;
  border-radius: 7px;
  border: 1px solid #f0f7ff;
  background: #f0f0f0;
  cursor: pointer;
  transform: scale(1);
  transition: .3s all ease-in-out;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  overflow: hidden;
  height: 200px; }
  .home .card:focus {
    outline: none; }
  .home .card--disabled {
    pointer-events: none;
    opacity: .5; }
  .home .card:hover:not(.card--disabled) {
    box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 75, 128, 0.1), 0 0 0 1px rgba(240, 247, 255, 0.9);
    border: 1px solid transparent;
    transform: scale(1.01); }
    .home .card:hover:not(.card--disabled) .card__img img {
      transform: scale(1); }
  @media screen and (max-width: 600px) {
    .home .card {
      height: 150px; } }
  .home .card__img {
    width: 100%;
    min-height: 120px;
    max-height: 100%;
    overflow: hidden; }
    .home .card__img img {
      transition: .3s transform ease-in-out;
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .home .card__content {
    padding: 20px 35px;
    display: flex;
    width: 100%;
    position: absolute;
    height: 100%;
    align-items: flex-end;
    color: white; }
    .home .card__content:after {
      content: '';
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 75%; }
    @media screen and (max-width: 600px) {
      .home .card__content {
        padding: 20px 15px; } }
    .home .card__content .title {
      margin-bottom: 5px;
      margin-top: 5px;
      font-size: 25px;
      display: flex;
      flex-direction: column; }
      .home .card__content .title__name {
        font-weight: 600; }
      .home .card__content .title__timeIndication {
        font-weight: 300;
        font-size: 18px; }
    .home .card__content p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 22px;
      color: #fff;
      opacity: 0.8; }
    .home .card__content button {
      margin-top: 10px;
      height: 50px;
      flex-shrink: 0; }
  .home .card__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-right: 35px;
    position: relative;
    z-index: 10; }
  .home .card__button {
    justify-content: flex-end;
    align-items: center;
    display: flex; }
  .home .card__remaining-time {
    display: none;
    align-items: center; }
    @media screen and (max-width: 600px) {
      .home .card__remaining-time {
        display: none; } }

.home__info-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  .home__info-cards .mat-card {
    margin: 0 0.5rem;
    margin-bottom: 20px;
    width: 100%; }
    @media screen and (min-width: 1280px) {
      .home__info-cards .mat-card {
        width: calc(50% - 1rem); } }

.home-signups__empty-message {
  padding: 0 15px;
  font-size: 1.25rem; }

.back {
  display: flex;
  background: white;
  border-radius: 20px;
  cursor: pointer;
  position: absolute;
  height: 40px;
  width: 40px;
  color: black;
  left: 0;
  top: -65px;
  overflow: hidden;
  transition: .2s all ease-in-out;
  transform: scale(1); }
  .back:hover {
    width: 105px;
    transform: scale(1.1); }
  .back__icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0; }
    .back__icon svg {
      width: 17px;
      height: 17px;
      margin-left: 14px;
      margin-top: 12px; }
  .back__text {
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    padding-right: 25px;
    width: 77px;
    font-weight: 600; }
  .back__wrap {
    width: 100%;
    position: relative; }

.no-content {
  text-align: center; }
  .no-content.mat-card {
    padding-bottom: 75px; }
    .no-content.mat-card h1 {
      margin-top: 40px;
      margin-bottom: 60px;
      max-width: 60%;
      margin-left: auto;
      margin-right: auto; }
      @media screen and (max-width: 600px) {
        .no-content.mat-card h1 {
          max-width: 100%; } }
  .no-content img {
    width: 50%;
    border-radius: 5px; }
    @media screen and (max-width: 600px) {
      .no-content img {
        width: 80%; } }

.login {
  width: 400px;
  max-width: 100%; }
  .login .mat-form-field {
    margin: 0; }
    .login .mat-form-field-wrapper {
      padding-bottom: 0.25rem; }
  .login .card {
    max-width: 400px;
    pointer-events: auto; }
  .login form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }

.dashboard {
  margin-top: 2rem; }
  .dashboard__header {
    display: flex;
    justify-content: space-between; }
  .dashboard .forms-dashboard .form {
    padding: 15px 20px;
    margin: 10px 0;
    display: flex;
    background: #f9f9f9;
    border-radius: 5px;
    align-items: center;
    height: 70px; }
    .dashboard .forms-dashboard .form mat-form-field {
      margin-top: -2px;
      margin-bottom: -22px; }
    .dashboard .forms-dashboard .form__name {
      font-weight: 600;
      font-size: 18px;
      width: 40%;
      padding-left: 20px; }
    .dashboard .forms-dashboard .form__enabled {
      padding: 0 10px; }
    .dashboard .forms-dashboard .form__start-date, .dashboard .forms-dashboard .form__end-date {
      width: 20%;
      text-align: center; }
  .dashboard .signup-table {
    width: 100%; }
    .dashboard .signup-table__wrap {
      overflow-x: scroll; }
    .dashboard .signup-table__empty {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      font-weight: 300;
      font-size: 18px; }
  .dashboard .news-table__image {
    display: flex;
    align-items: center;
    justify-content: center; }
    .dashboard .news-table__image img {
      width: 100px;
      height: 75px;
      object-fit: cover;
      border-radius: 5px;
      background-color: #F4F4F4; }
  .dashboard .news-table td {
    padding: 1rem; }
  .dashboard .calendar-table,
  .dashboard .news-table {
    width: 100%; }
    .dashboard .calendar-table__row,
    .dashboard .news-table__row {
      display: table-row;
      border-color: inherit; }
      .dashboard .calendar-table__row:hover,
      .dashboard .news-table__row:hover {
        background: rgba(0, 0, 0, 0.02); }
    .dashboard .calendar-table__wrap,
    .dashboard .news-table__wrap {
      overflow-x: scroll; }
    .dashboard .calendar-table__content,
    .dashboard .news-table__content {
      width: 100%; }
    .dashboard .calendar-table__title,
    .dashboard .news-table__title {
      min-width: 200px;
      font-weight: 600; }
    .dashboard .calendar-table__timestamp, .dashboard .calendar-table__is-active,
    .dashboard .news-table__timestamp,
    .dashboard .news-table__is-active {
      flex-shrink: 0; }
  .dashboard .calendar-row__delete {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.25rem 0; }
  .dashboard .calendar-row__title {
    font-size: 1rem;
    font-weight: 600;
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }
  .dashboard .calendar-row__edit {
    width: 50px; }
  .dashboard .calendar-row__date .end-date__icon {
    margin: 0 0.5rem; }
  .dashboard table {
    margin-top: 10px;
    border-collapse: collapse;
    overflow-x: scroll;
    font-size: 14px; }
  .dashboard thead {
    color: #aaa; }
  .dashboard tbody .name {
    font-weight: 600;
    min-width: 200px; }
  .dashboard tbody .group, .dashboard tbody .tshirt {
    min-width: 100px; }
  .dashboard tbody .gender {
    font-size: 20px; }
  .dashboard tbody .street {
    min-width: 170px; }
  .dashboard tbody .cpName {
    min-width: 130px; }
  .dashboard tbody .comments {
    min-width: 300px; }
  .dashboard tbody .email {
    min-width: 200px; }
  .dashboard tbody .icon {
    min-width: 100px; }
  .dashboard tbody tr:hover {
    background: rgba(0, 0, 0, 0.02); }
  .dashboard tr {
    border-bottom: 1px solid #eee; }
  .dashboard td {
    padding: 0.5rem 1rem;
    vertical-align: middle; }

.alert {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 270px;
  font-size: 14px;
  margin: 5px 0; }
  .alert--warning {
    background: #FFF5E9;
    color: #FAA434; }
  .alert__content {
    padding-left: 10px; }

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
  background-color: #007AFF; }

.mat-form-field.mat-focused .mat-form-field-label {
  color: #007AFF; }

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #007AFF; }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #007AFF; }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(0, 122, 255, 0.5); }

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #007AFF; }

.mat-radio-button.mat-primary .mat-radio-inner-circle {
  background-color: #007AFF; }

.mat-checkbox-checked.mat-primary .mat-checkbox-background, .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
  background-color: #007AFF; }

.dashboard-form {
  display: flex;
  padding: 20px 0;
  flex-wrap: wrap; }
  .dashboard-form .mat-slide-toggle {
    display: none !important; }
  .dashboard-form__enabled {
    width: 7%;
    display: none; }
    @media screen and (max-width: 600px) {
      .dashboard-form__enabled {
        width: 20%;
        padding: 10px 0; } }
  .dashboard-form__name {
    font-weight: bold;
    font-size: 19px;
    padding: 0 10px;
    width: 30%; }
    @media screen and (max-width: 600px) {
      .dashboard-form__name {
        width: 80%;
        padding: 10px 0; } }
  .dashboard-form__dates {
    width: 30%; }
    @media screen and (max-width: 600px) {
      .dashboard-form__dates {
        width: 100%; } }
    @media screen and (max-width: 600px) {
      .dashboard-form__dates-inner {
        display: flex;
        justify-content: space-between; }
        .dashboard-form__dates-inner strong {
          display: none; } }
  .dashboard-form__status {
    width: 40%;
    text-align: right; }
    @media screen and (max-width: 600px) {
      .dashboard-form__status {
        width: 100%;
        text-align: left;
        padding: 10px 0; } }
  .dashboard-form__startdate, .dashboard-form__enddate {
    margin: 0 15px;
    width: 30%;
    background: #eee;
    border-radius: 15px;
    padding: 5px 15px;
    font-size: 14px; }
    @media screen and (max-width: 600px) {
      .dashboard-form__startdate, .dashboard-form__enddate {
        margin: 0; } }
    @media screen and (max-width: 600px) {
      .dashboard-form__startdate-wrap, .dashboard-form__enddate-wrap {
        width: 100%;
        padding: 10px 0; } }
  @media screen and (max-width: 600px) {
    .dashboard-form__enddate-wrap {
      text-align: right; } }
  .dashboard-form.notActive .dashboard-form__status {
    color: #888; }
  .dashboard-form.inBetween .dashboard-form__status {
    color: limegreen; }
  .dashboard-form.before .dashboard-form__status, .dashboard-form.after .dashboard-form__status {
    color: orange; }

.simple-layout {
  display: flex;
  background: #fff; }
  .simple-layout__right {
    width: 100%;
    height: 100vh;
    overflow: scroll; }
  .simple-layout__main {
    height: 100%; }
  .simple-layout__right-content {
    height: 100%;
    min-width: 50vw; }

.sidebar {
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  background: #007AFF;
  width: 60px;
  height: 100%;
  position: relative;
  transition: .1s width ease-in-out;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#007aff+0,003df4+100 */
  background: #263241;
  border-right: 1px solid #0062cc; }
  .sidebar .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    cursor: pointer; }
    .sidebar .logo img {
      width: 35px;
      position: relative;
      margin-left: -1px; }
  .sidebar__nav {
    padding: 15px;
    height: 100%;
    width: 250px;
    margin-top: 0;
    padding-top: 1.5rem; }
    .sidebar__nav .nav-item {
      list-style: none;
      color: white;
      font-weight: 500;
      flex-wrap: wrap;
      cursor: pointer;
      display: none; }
      .sidebar__nav .nav-item--toggle.isOpen .nav-item__toggle {
        transform: rotate(-180deg); }
      .sidebar__nav .nav-item__toggle {
        margin-right: 0; }
      .sidebar__nav .nav-item__text {
        width: 100%; }
      .sidebar__nav .nav-item.active .nav-item__inner {
        background: rgba(0, 0, 0, 0.2);
        color: white;
        font-weight: 500; }
      .sidebar__nav .nav-item__inner {
        padding: 12px 20px;
        margin: 4px 0;
        font-size: 14px;
        border-radius: 3px;
        width: 100%;
        display: flex;
        font-weight: 400; }
        .sidebar__nav .nav-item__inner:hover {
          background-color: rgba(255, 255, 255, 0.2); }
    .sidebar__nav-item fa-icon {
      margin-right: 15px; }
  .sidebar__second-level {
    width: 100%;
    margin-top: -4px; }
    .sidebar__second-level .nav-item__inner {
      margin: 2px 0; }
  .sidebar__logout {
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-size: 20px;
    flex-shrink: 0;
    display: none; }
  .sidebar__expand {
    padding: 0.25rem;
    margin-top: 5px;
    cursor: pointer;
    position: absolute;
    top: 160px;
    transform: translateY(-50%);
    right: -23px;
    z-index: 10;
    border-left: none; }
    .sidebar__expand-inner {
      box-shadow: 0px 15px 30px 0px rgba(65, 95, 185, 0.18);
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      width: 35px;
      height: 35px;
      background: #fff;
      border-radius: 50%;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
    .sidebar__expand:hover-inner {
      background: #f6f6f6; }
    .sidebar__expand fa-icon {
      color: #2E2E2E;
      font-size: 14px; }
  .sidebar--expanded {
    width: 250px; }
    .sidebar--expanded .logo {
      height: 150px;
      width: 250px; }
      .sidebar--expanded .logo img {
        width: 100px;
        position: relative;
        left: -8px;
        margin-left: 0; }
    .sidebar--expanded .sidebar__expand {
      justify-content: center; }
    .sidebar--expanded .sidebar__nav li {
      display: flex; }
    .sidebar--expanded .sidebar__logout {
      padding: 0 20px;
      margin-top: 1.5rem;
      height: 40px;
      flex-shrink: 0;
      margin-bottom: 10px;
      font-size: 14px;
      border-radius: 3px;
      display: flex;
      background-color: #1d2631;
      width: 220px;
      display: block; }
      .sidebar--expanded .sidebar__logout:hover {
        background-color: #303f51; }
      .sidebar--expanded .sidebar__logout fa-icon {
        margin-right: 10px; }

.activity-cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem; }
  .activity-cards__card {
    background: #1a1b1e; }
    .activity-cards__card .mat-card__title {
      color: #fff; }
  .activity-cards__empty-message {
    padding: 0 1rem;
    margin-top: 0;
    font-size: 1.25rem; }
  .activity-cards .activity-card {
    margin: 0 1rem;
    border-radius: 7px;
    background: #007AFF;
    color: #fff;
    display: flex;
    height: 100%;
    overflow: hidden;
    width: calc(100% - 2rem);
    border-bottom: 3px solid #0062cc; }
    .activity-cards .activity-card--w-enddate {
      padding: 0.5rem 0; }
      .activity-cards .activity-card--w-enddate .activity-card__month {
        font-size: 14px;
        line-height: 12px; }
      .activity-cards .activity-card--w-enddate .activity-card__day {
        font-size: 20px; }
      .activity-cards .activity-card--w-enddate .activity-card__startdate::after {
        content: '-';
        font-size: 1rem;
        line-height: 1rem;
        opacity: .5;
        margin-bottom: 2px; }
    .activity-cards .activity-card__startdate, .activity-cards .activity-card__enddate {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
    .activity-cards .activity-card__right {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      width: calc(100% - 65px);
      align-items: flex-start; }
    .activity-cards .activity-card__date {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      flex-direction: column;
      background: #0073f0;
      flex-shrink: 0; }
    .activity-cards .activity-card__title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0.5rem;
      margin-top: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      line-height: 1;
      flex-shrink: 0;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal; }
    .activity-cards .activity-card__day {
      font-size: 2rem;
      line-height: 1; }
    .activity-cards .activity-card__month {
      color: #b3d7ff;
      font-size: 18px; }
    .activity-cards .activity-card__description {
      height: 100%;
      padding-bottom: 0.5rem;
      font-weight: 300; }
    .activity-cards .activity-card__link {
      color: white;
      font-weight: bold;
      text-decoration: none;
      padding: 0.75rem 1rem;
      margin: 0.25rem 0;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
      display: inline-flex;
      margin-top: 1rem; }
      .activity-cards .activity-card__link fa-icon {
        margin-right: 0.5rem;
        opacity: 0.7; }
      .activity-cards .activity-card__link:hover {
        background: rgba(0, 0, 0, 0.2); }
    .activity-cards .activity-card__location {
      color: #b3d7ff; }
      .activity-cards .activity-card__location fa-icon {
        margin-right: 0.5rem;
        font-size: 14px; }
  @media screen and (max-width: 960px) {
    .activity-cards .activity-card__wrap {
      width: 100%;
      display: none; }
      .activity-cards .activity-card__wrap:first-child {
        display: flex; } }

.calendar-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white; }
  .calendar-link__content {
    padding-left: 0.5rem;
    cursor: pointer;
    display: none; }
    @media screen and (min-width: 600px) {
      .calendar-link__content {
        display: block; } }
  .calendar-link:hover {
    text-decoration: underline; }

app-modal {
  display: none; }
  app-modal .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center; }
    app-modal .modal__close-btn {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      padding: 1rem;
      cursor: pointer; }
      app-modal .modal__close-btn fa-icon {
        margin: 0;
        font-size: 1rem;
        color: #A7A7A7;
        background: rgba(255, 255, 255, 0.3);
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; }
      app-modal .modal__close-btn:hover fa-icon {
        color: #626262; }
    app-modal .modal__inner {
      position: relative;
      display: flex; }
    app-modal .modal .mat-card__body {
      max-height: calc(100vh - 330px);
      overflow-x: visible;
      overflow-y: auto;
      overscroll-behavior: contain; }
      app-modal .modal .mat-card__body form {
        overflow: hidden; }
    app-modal .modal .mat-card__header {
      padding-right: 50px; }
    app-modal .modal .mat-card__footer button {
      margin-bottom: 0; }
    app-modal .modal .mat-card__close {
      position: absolute;
      right: 0.5rem;
      padding: 1rem;
      font-size: 20px;
      cursor: pointer;
      top: 0.5rem;
      transform: scale(1);
      transition: transform .2s ease-in-out; }
      app-modal .modal .mat-card__close:hover {
        transform: scale(1.1);
        transition: transform .2s ease-in-out; }
    app-modal .modal__backdrop {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: rgba(0, 42, 73, 0.5);
      background: rgba(0, 49, 102, 0.5);
      position: fixed;
      z-index: 5;
      left: 0;
      top: 0; }

.modal-container {
  max-width: calc(100% - 2rem); }

.calendar .card {
  max-width: 700px; }

.calendar-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0; }
  .calendar-row:last-child {
    border: none; }
  .calendar-row td {
    padding: 0.5rem; }
  .calendar-row__date {
    display: flex; }
  .calendar-row__title {
    font-size: 24px;
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 300; }
  .calendar-row__right {
    padding-left: 1rem; }
  .calendar-row__subtitle {
    font-weight: 300;
    color: #888; }

.calendar .mat-card__header {
  padding-bottom: 0;
  display: flex; }

.calendar .mat-card__titles-wrap {
  flex-direction: column;
  align-items: flex-start; }

.calendar .mat-card__logo {
  display: none; }
  .calendar .mat-card__logo img {
    width: 100px;
    margin-right: 50px; }

.calendar .start-date, .calendar .end-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  position: relative; }
  .calendar .start-date__day, .calendar .end-date__day {
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 1; }
  .calendar .start-date__month, .calendar .end-date__month {
    font-size: 20px;
    font-weight: 300; }

.calendar .end-date__icon {
  position: absolute;
  left: -0.5rem;
  top: 50%;
  transform: translateY(-50%); }

.calendar .start-date {
  padding-left: 0; }

.edit-calendar {
  width: 500px; }
  .edit-calendar textarea {
    min-height: 135px; }

\.add-activity-modal {
  max-width: 500px;
  width: calc(100vw - 30px); }
  \.add-activity-modal__startdate, \.add-activity-modal__enddate {
    width: 50%; }

.form__inner {
  display: flex;
  flex-direction: column; }

.form-field-wrapper {
  display: flex; }

.styled-checkbox__input {
  position: absolute;
  opacity: 0; }
  .styled-checkbox__input + label {
    position: relative;
    cursor: pointer;
    padding: 0.5rem; }
  .styled-checkbox__input + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 2px solid #C3C3C3;
    border-radius: 4px; }
  .styled-checkbox__input:hover + label:before {
    background: #2E2E2E;
    border-color: #2E2E2E; }
  .styled-checkbox__input:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); }
  .styled-checkbox__input:checked + label:before {
    background: #2E2E2E;
    border-color: #2E2E2E; }
  .styled-checkbox__input:checked + label:after {
    content: '';
    position: absolute;
    left: 13px;
    top: 17px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg); }

.mat-card {
  box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 75, 128, 0.1) !important;
  padding: 35px;
  display: inline-block;
  overflow: hidden;
  border-radius: 7px !important;
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }
  .mat-card__close {
    position: absolute;
    color: white;
    top: 1rem;
    right: 1rem;
    z-index: 100; }
    .mat-card__close .icon {
      -webkit-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3)); }
  .mat-card__title {
    margin: 0;
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 600;
    font-size: calc(1.275rem + 0.3vw); }
    @media (min-width: 1200px) {
      .mat-card__title {
        font-size: 1.5rem; } }
    .mat-card__title.mobile {
      display: none; }
  .mat-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem; }
    .mat-card__header button.mat-raised-button {
      margin: 0; }
    .mat-card__header-left {
      display: flex;
      align-items: center; }
  .mat-card__footer {
    display: flex;
    justify-content: center; }
    .mat-card__footer button {
      margin: 0; }
  .mat-card__subtitle {
    font-weight: 300;
    margin: 1rem 0; }
  .mat-card.photo-section img {
    object-position: 50% 25%; }
  .mat-card.building-section img {
    object-position: 30% 50%; }
  .mat-card--image-left, .mat-card--image-right {
    padding: 0 !important;
    display: flex !important;
    overflow: hidden; }
    .mat-card--image-left:hover .mat-card__image img, .mat-card--image-right:hover .mat-card__image img {
      -ms-transform: skew(15deg, 0deg) scale(1.05);
      /* IE 9 */
      -webkit-transform: skew(15deg, 0deg) scale(1.05);
      /* Safari */
      transform: skew(15deg, 0deg) scale(1.05);
      /* Standard syntax */
      transition: transform .3s ease-out; }
    .mat-card--image-left .mat-card__image, .mat-card--image-right .mat-card__image {
      width: calc(50% + 50px);
      -ms-transform: skew(-15deg, 0deg);
      /* IE 9 */
      -webkit-transform: skew(-15deg, 0deg);
      /* Safari */
      transform: skew(-15deg, 0deg);
      /* Standard syntax */
      overflow: hidden;
      height: 100%;
      height: 200px;
      position: relative; }
      .mat-card--image-left .mat-card__image img, .mat-card--image-right .mat-card__image img {
        object-fit: cover;
        -ms-transform: skew(15deg, 0deg) scale(1);
        /* IE 9 */
        -webkit-transform: skew(15deg, 0deg) scale(1);
        /* Safari */
        transform: skew(15deg, 0deg) scale(1);
        /* Standard syntax */
        position: absolute;
        top: -50px;
        left: -50px;
        width: calc(100% + 100px);
        height: calc(100% + 100px);
        transition: transform .3s ease-out; }
    .mat-card--image-left .mat-card__title, .mat-card--image-right .mat-card__title {
      color: white;
      margin-bottom: 1rem; }
    .mat-card--image-left .mat-card__content, .mat-card--image-right .mat-card__content {
      padding: 1.5rem;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
  .mat-card--image-left .mat-card__image {
    margin-left: -50px;
    width: 100%; }
  .mat-card--image-left .mat-card__content {
    width: 285px;
    flex-shrink: 0; }
  .mat-card--image-right .mat-card__image {
    margin-right: -50px;
    width: 100%; }
  .mat-card--image-right .mat-card__content {
    width: 235px;
    flex-shrink: 0; }
  .mat-card--green {
    background: #12a96d;
    box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(18, 169, 109, 0.2) !important; }
    .mat-card--green .mat-card__content .mat-raised-button {
      background-color: #0d7b4f; }
  .mat-card--blue {
    background: #007AFF;
    box-shadow: 0 0.4rem 0.8rem -0.1rem rgba(0, 122, 255, 0.2) !important; }
    .mat-card--blue .mat-card__content .mat-raised-button {
      background-color: #0062cc; }
  @media screen and (max-width: 960px) {
    .mat-card__title.desktop {
      display: none; }
    .mat-card__title.mobile {
      display: block; } }

.section__title {
  margin-top: 2rem; }

.news-wrapper {
  padding-bottom: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  flex-wrap: wrap; }
  .news-wrapper__content {
    display: flex;
    flex-wrap: wrap; }
  .news-wrapper__link {
    display: flex;
    justify-content: flex-end;
    margin: 0 8px;
    position: relative; }
    .news-wrapper__link button {
      margin: 16px 0 8px;
      position: relative;
      z-index: 5;
      background: white;
      color: black; }
    .news-wrapper__link::after {
      width: 100%;
      height: 1px;
      content: "";
      background: #eee;
      position: absolute;
      top: 50%; }
  .news-wrapper .news-item {
    padding: 0;
    width: calc(100% - 1rem);
    margin: 0.5rem;
    cursor: pointer; }
    @media screen and (min-width: 600px) {
      .news-wrapper .news-item {
        width: calc(100% / 2 - 1rem); } }
    @media screen and (min-width: 960px) {
      .news-wrapper .news-item {
        width: calc(100% / 3 - 1rem); } }
    .news-wrapper .news-item__inner {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .news-wrapper .news-item:hover .news-item__image img {
      transform: scale(1.05);
      transition: transform .3s ease-out; }
    .news-wrapper .news-item:first-child {
      width: calc(100% - 1rem); }
      @media screen and (min-width: 600px) {
        .news-wrapper .news-item:first-child {
          width: calc(100% / 2 - 1rem); } }
      @media screen and (min-width: 960px) {
        .news-wrapper .news-item:first-child {
          width: calc(2 * 100% / 3 - 1rem);
          flex-direction: row; }
          .news-wrapper .news-item:first-child .news-item__content {
            width: 300px; }
          .news-wrapper .news-item:first-child .news-item__image {
            order: 1;
            width: calc(100% - 300px); }
            .news-wrapper .news-item:first-child .news-item__image img {
              width: 100%;
              height: 100%; }
          .news-wrapper .news-item:first-child .news-item__summary {
            display: flex; }
          .news-wrapper .news-item:first-child .news-item__inner {
            display: flex;
            flex-direction: row; } }
    .news-wrapper .news-item__content {
      padding: 1.5rem;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      height: 100%; }
      .news-wrapper .news-item__content-inner {
        height: 100%;
        display: flex;
        flex-direction: column; }
      .news-wrapper .news-item__content p {
        margin-top: 0; }
    .news-wrapper .news-item__title {
      margin: 0;
      margin-bottom: 0.75rem;
      font-size: 24px;
      line-height: 1.1;
      font-weight: 600; }
    .news-wrapper .news-item__summary {
      height: 100%;
      margin: 0;
      margin-bottom: 1rem;
      color: #737373;
      font-size: 1rem;
      line-height: 1.5;
      font-weight: 300;
      display: none; }
    .news-wrapper .news-item__image {
      display: flex;
      overflow: hidden;
      flex-shrink: 0; }
      .news-wrapper .news-item__image img {
        width: 100%;
        height: 165px;
        object-fit: cover;
        transform: scale(1);
        transition: transform .3s ease-out;
        background: #f0f0f0; }
    .news-wrapper .news-item--promo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 440px; }
      .news-wrapper .news-item--promo .news-item__image {
        position: absolute;
        width: 100% !important;
        height: 100%;
        top: 0;
        left: 0; }
        .news-wrapper .news-item--promo .news-item__image img {
          height: 100%; }
      .news-wrapper .news-item--promo .news-item__content {
        position: absolute;
        width: 100% !important;
        height: auto;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        max-width: 340px; }
        .news-wrapper .news-item--promo .news-item__content img {
          width: 280px;
          margin-bottom: 1rem; }
        .news-wrapper .news-item--promo .news-item__content p {
          color: #1E4B4C;
          margin-top: 0.75rem;
          text-align: center;
          font-weight: 600; }
        .news-wrapper .news-item--promo .news-item__content .btn {
          margin-top: 0.5rem;
          color: white;
          background-color: #34424C;
          border: none; }
        .news-wrapper .news-item--promo .news-item__content .subtitle {
          font-size: 20px;
          font-weight: 400;
          margin-bottom: 1rem; }
    .news-wrapper .news-item__bottom {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #eee;
      padding-top: 1rem;
      font-size: 14px;
      flex-shrink: 0;
      color: #4c4c4c; }
    .news-wrapper .news-item__date {
      display: flex;
      color: #4c4c4c; }
      .news-wrapper .news-item__date fa-icon {
        margin-right: 0.5rem;
        display: flex;
        align-items: center; }
      .news-wrapper .news-item__date-text {
        margin-top: 3px; }

.image-upload__button {
  background: #007AFF;
  color: #fff;
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer; }
  .image-upload__button input {
    display: none; }

.image-upload__preview {
  background: center center no-repeat;
  background-size: contain;
  height: 116px;
  width: 116px; }

.dropzone {
  height: 200px;
  border: 3px dashed #bbb;
  border-radius: 5px;
  background: #eee;
  margin: 1rem 0; }
  .dropzone.hovering {
    background: #e1e1e1; }
  .dropzone__init, .dropzone__upload {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%; }
  .dropzone fa-icon {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem; }
  .dropzone progress {
    /* Reset the default appearance */
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 0.5rem;
    border-radius: 50%;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem; }
  .dropzone progress::-webkit-progress-bar {
    border-radius: 10px;
    background-color: #bbb; }
  .dropzone progress::-webkit-progress-value {
    border-radius: 10px;
    background-color: #4c4c4c; }
  .dropzone__title {
    color: #4c4c4c;
    font-weight: normal;
    font-size: 1.25rem;
    margin: 0 0 0.5rem 0; }
  .dropzone__subtitle {
    color: #8d8d8d;
    font-weight: normal;
    font-size: 1rem;
    margin: 0 0 0.5rem 0; }
  .dropzone__file {
    border-radius: 5px;
    background: #eee;
    margin: 1rem 0;
    height: 250px;
    width: 100%; }
    .dropzone__file img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

progress::-webkit-progress-value {
  transition: width 0.1s ease; }

#add-newsitem-modal .add-newsitem-modal {
  width: calc(100vw - 30px);
  max-width: 600px; }
  #add-newsitem-modal .add-newsitem-modal form {
    overflow: hidden; }

#add-newsitem-modal .add-news-item__shortcontent {
  margin-bottom: 1rem; }

#add-newsitem-modal .add-news-item textarea {
  min-height: 100px; }

#add-newsitem-modal .add-news-item ckeditor {
  width: 100%;
  margin-bottom: 1rem; }
  #add-newsitem-modal .add-news-item ckeditor .ck-content {
    min-height: 250px; }

#add-newsitem-modal .add-news-item__online {
  margin: 1rem 0; }
  #add-newsitem-modal .add-news-item__online .mat-slide-toggle {
    width: 100%; }
    #add-newsitem-modal .add-news-item__online .mat-slide-toggle-label {
      display: flex;
      justify-content: space-between; }

#add-newsitem-modal .add-news-item .mat-form-field {
  margin-left: 0;
  margin-right: 0; }

#add-newsitem-modal .file-upload__wrap {
  position: relative; }

#add-newsitem-modal .file-upload__remove {
  position: absolute;
  right: 1rem;
  top: 2rem; }

.modal .news-item-modal {
  width: calc(100vw - 30px);
  max-width: 800px; }
  .modal .news-item-modal__date {
    display: flex;
    color: #737373;
    font-size: 1rem;
    margin-bottom: 1rem; }
    .modal .news-item-modal__date fa-icon {
      margin-right: 0.5rem;
      display: flex;
      align-items: center; }
    .modal .news-item-modal__date-text {
      margin-top: 3px; }
  .modal .news-item-modal .mat-card {
    padding: 0;
    width: 100%;
    max-height: 85vh;
    display: flex;
    flex-direction: column; }
    .modal .news-item-modal .mat-card__header {
      background: #7a828c;
      padding: 0;
      position: relative;
      display: flex;
      align-items: flex-end;
      max-height: 40vh; }
      .modal .news-item-modal .mat-card__header img {
        width: 100%;
        height: 300px;
        object-fit: cover; }
        @media screen and (min-width: 960px) {
          .modal .news-item-modal .mat-card__header img {
            height: 500px; } }
      .modal .news-item-modal .mat-card__header:after {
        content: '';
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 75%; }
    .modal .news-item-modal .mat-card__title {
      position: absolute;
      padding: 2rem;
      color: #fff;
      z-index: 20; }
    .modal .news-item-modal .mat-card__body {
      margin: 1.5rem 2rem !important;
      font-size: 1.125rem;
      line-height: 1.5;
      font-weight: 300;
      color: #4c4c4c;
      overflow: scroll; }
      .modal .news-item-modal .mat-card__body p {
        margin-top: 0;
        margin-bottom: 0.5rem; }
  .modal .news-item-modal blockquote {
    font-size: 1.4em;
    width: 75%;
    margin: 50px auto;
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: 700;
    font-style: italic;
    color: #4c4c4c;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #007AFF;
    line-height: 1.6;
    position: relative; }
    .modal .news-item-modal blockquote p {
      margin-top: 0;
      line-height: 1.5;
      margin-bottom: 0; }
  .modal .news-item-modal blockquote::before {
    font-family: Arial;
    content: "\201C";
    color: #007AFF;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px; }
  .modal .news-item-modal blockquote::after {
    content: ''; }
  .modal .news-item-modal blockquote span {
    display: block;
    color: #333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em; }

.maintenance mat-card {
  display: flex;
  flex-direction: column; }

.maintenance__img {
  width: 100%;
  display: block;
  border-radius: 7px;
  order: 1; }

.maintenance__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem !important; }

.maintenance__title {
  font-size: 2rem;
  margin: 0.5rem 0; }

.maintenance__subtitle {
  color: #7a828c;
  margin: 0.5rem 0; }

@media screen and (min-width: 600px) {
  .maintenance mat-card {
    flex-direction: row; }
  .maintenance__img {
    width: 40%; }
  .maintenance__title {
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif;
    font-size: 2.75rem;
    margin: 1rem 0; }
  .maintenance__content {
    padding-left: 40px;
    margin-bottom: 0 !important; }
  .maintenance__img {
    order: 0; } }

.kamp-wrap h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem; }

.kamp-wrap h3 {
  margin: 0;
  padding-top: 1rem; }

.kamp-wrap h4 {
  margin-bottom: 0;
  margin-top: 1rem; }

.kamp-wrap .subtitle {
  color: #7a828c;
  margin: 0.5rem 0; }

.kamp-wrap .mat-card--kamp {
  overflow: inherit; }

.kamp-wrap .price-card__coin-image {
  width: 3.5rem;
  position: absolute;
  top: -0.75rem;
  right: 3rem; }

.mat-slide-toggle-label {
  flex-direction: row-reverse !important;
  justify-content: space-between; }

.mat-slide-toggle-bar {
  height: 48px !important;
  width: 120px !important;
  border: 1px solid #e9e9e9;
  background: transparent;
  border-radius: 4px !important;
  position: relative; }
  .mat-slide-toggle-bar:before {
    content: 'Nee';
    position: absolute;
    top: 12px;
    left: 16px; }
  .mat-slide-toggle-bar:after {
    content: 'Ja';
    position: absolute;
    top: 12px;
    right: 22px; }

.mat-slide-toggle-thumb {
  background: #494949 !important;
  height: 38px !important;
  width: 56px !important;
  border-radius: 3px !important;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mat-slide-toggle-thumb:before {
    content: 'Nee';
    color: white;
    position: relative;
    top: 1px; }
  .mat-slide-toggle-thumb-container {
    height: 38px !important;
    width: 56px !important;
    top: 4px !important;
    left: 4px !important; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  right: 4px !important;
  left: auto !important;
  transform: none !important; }

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb:before {
  content: 'Ja'; }

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: transparent !important; }

.health-questions__row {
  border-bottom: 1px dashed #e9e9e9; }

.shop-item {
  display: flex;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0.5rem; }
  .shop-item__image img {
    width: 130px;
    border-radius: 5px; }
  .shop-item__content {
    display: flex;
    padding-left: 2rem;
    width: 100%;
    align-items: center; }
  .shop-item__title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 2rem; }
  .shop-item__price {
    font-size: 1.5rem;
    color: #737373; }
  .shop-item__select {
    display: flex;
    align-items: center;
    padding: 24px; }
  .shop-item__success {
    color: #49d361;
    display: flex;
    align-items: center; }
    .shop-item__success .icon {
      background: #49d361;
      color: white;
      border-radius: 50%;
      width: 1.75rem;
      height: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem; }
  .shop-item__remove {
    text-decoration: underline;
    color: #737373;
    cursor: pointer;
    padding: 0.5rem 0; }
  @media screen and (max-width: 600px) {
    .shop-item {
      flex-direction: column; }
      .shop-item__image img {
        width: 100%; }
      .shop-item__content {
        padding: 0 0.5rem;
        justify-content: space-between; }
      .shop-item__select {
        padding: 0.5rem; } }

.koekenfestijn * {
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }

.koekenfestijn h2 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.5rem; }

.koekenfestijn h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 0; }

.koekenfestijn p {
  font-size: 1.125rem;
  max-width: 50ch;
  margin-top: 0;
  color: #626262; }

.koekenfestijn strong {
  font-weight: 600;
  color: #1C1C1C; }

.koekenfestijn .page-title {
  font-weight: 600; }

.koekenfestijn .mat-card--form {
  padding-top: 2rem !important; }

.koekenfestijn .mat-card__title {
  font-weight: 600; }

.koekenfestijn .mat-form-field-infix {
  border-top: none !important;
  display: flex;
  padding-top: 1.75em;
  padding-bottom: 1.5em;
  padding-left: 0.5rem;
  padding-right: 0.5em; }

.koekenfestijn .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none; }

.koekenfestijn .mat-form-field {
  margin: 0;
  width: 100%; }

.koekenfestijn .mat-select-value,
.koekenfestijn .mat-input-element {
  font-size: 1.125rem; }

.koekenfestijn-form {
  display: flex;
  flex-wrap: wrap; }
  .koekenfestijn-form .label {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 2rem; }
  .koekenfestijn-form__name-and-amount {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: auto 200px;
    grid-gap: 1rem; }

.koekenfestijn .pickup {
  border-bottom: 1px solid #E9E9E9;
  padding: 2rem 0; }
  .koekenfestijn .pickup p {
    margin-bottom: 2rem; }

.koekenfestijn .payment {
  padding: 2rem 0;
  border-bottom: 1px solid #E9E9E9; }
  .koekenfestijn .payment-card {
    background: #F4F4F4;
    padding: 1.5rem;
    border-radius: 7px;
    display: grid;
    grid-template-columns: 200px auto;
    align-items: center; }
    .koekenfestijn .payment-card__price {
      font-weight: 600;
      text-align: center;
      font-size: 2.25rem;
      padding-right: 1.5rem;
      border-right: 1px solid #DADADA; }
    .koekenfestijn .payment-card__text {
      font-size: 1.125rem;
      color: #494949;
      padding: 1rem 3rem; }

.koekenfestijn .pickup-hours {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  padding: 0;
  margin: 0.25rem 0; }
  .koekenfestijn .pickup-hours li {
    border: 1px solid #E9E9E9;
    border-radius: 7px;
    padding: 1.5rem;
    font-size: 1.125rem;
    text-align: center;
    list-style: none;
    cursor: pointer;
    color: #494949;
    position: relative; }
    .koekenfestijn .pickup-hours li:hover {
      border-color: #A7A7A7; }
    .koekenfestijn .pickup-hours li.active {
      border: 2px solid #1C1C1C;
      font-weight: 600;
      color: #1C1C1C; }
      .koekenfestijn .pickup-hours li.active::after {
        --borderWidth: 2px;
        --height: 14px;
        --width: 6px;
        --borderColor: #fff;
        content: '';
        display: inline-block;
        transform: rotate(45deg);
        height: var(--height);
        width: var(--width);
        border-bottom: var(--borderWidth) solid var(--borderColor);
        border-right: var(--borderWidth) solid var(--borderColor);
        position: absolute;
        right: 1px;
        top: -4px; }
      .koekenfestijn .pickup-hours li.active::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1.75rem;
        height: 1.75rem;
        background: #1C1C1C;
        border-radius: 50%;
        transform: translate(35%, -35%); }

.koekenfestijn .mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0; }

.koekenfestijn .btn--primary {
  background: #1C1C1C;
  border-radius: 7px;
  width: 100%;
  color: #fff;
  font-size: 1.125rem;
  margin: 2rem 0;
  text-align: center;
  justify-content: center;
  cursor: pointer; }
  .koekenfestijn .btn--primary:disabled {
    opacity: 0.7;
    pointer-events: none; }

.koekenfestijn .summary {
  background: #F4F4F4;
  padding: 1.5rem;
  border-radius: 7px; }
  .koekenfestijn .summary__item {
    display: flex;
    padding: 0.25rem 0; }
  .koekenfestijn .summary__label {
    font-weight: 600;
    width: 200px; }

@media screen and (max-width: 600px) {
  .koekenfestijn-form__name-and-amount {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto; }
  .koekenfestijn .payment-card {
    grid-template-columns: auto; }
    .koekenfestijn .payment-card__price {
      font-size: calc(1.325rem + 0.9vw);
      border-right: none;
      text-align: left; } }
    @media screen and (max-width: 600px) and (min-width: 1200px) {
      .koekenfestijn .payment-card__price {
        font-size: 2rem; } }

@media screen and (max-width: 600px) {
    .koekenfestijn .payment-card__text {
      padding: 1rem 0;
      text-align: left; }
  .koekenfestijn .pickup-hours {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .koekenfestijn .summary__item {
    flex-flow: column;
    padding: 0.5rem 0; }
  .koekenfestijn .card--top .page-subtitle {
    font-size: 1.125rem;
    line-height: 1.5; }
  .koekenfestijn .card--top .page-title__primary {
    font-size: 2rem; } }

#delete-sloerojo-signups-modal .delete-sloerojo-signups-modal {
  width: calc(100vw - 30px);
  max-width: 450px; }
  #delete-sloerojo-signups-modal .delete-sloerojo-signups-modal p {
    margin-top: 0; }

app-dashboard-sloerojowknd table {
  width: 100%; }

.breadcrumbs {
  display: flex;
  padding: 0;
  margin: 0 -0.5rem; }
  .breadcrumbs .breadcrumb {
    list-style: none;
    padding: 0;
    display: flex;
    font-size: 0.875rem;
    color: #494949; }
    .breadcrumbs .breadcrumb:hover {
      color: #0a0a0a; }
    .breadcrumbs .breadcrumb--current {
      color: #A7A7A7; }
      .breadcrumbs .breadcrumb--current:hover {
        color: #A7A7A7; }
    .breadcrumbs .breadcrumb__link {
      padding: 0.5rem;
      text-decoration: none;
      color: currentColor; }
    .breadcrumbs .breadcrumb + .breadcrumb {
      margin-left: 1rem;
      position: relative; }
      .breadcrumbs .breadcrumb + .breadcrumb:before {
        content: '/';
        position: absolute;
        left: -0.75rem;
        color: #C3C3C3;
        display: flex;
        height: 100%;
        align-items: center; }

.db-table {
  border-bottom: 1px solid #eee;
  width: 100%; }
  .db-table__wrap {
    overflow-y: scroll; }
  .db-table__row {
    display: table-row; }
    .db-table__row--highlighted {
      background: #F4F4F4; }
  .db-table__delete {
    color: #f44336;
    cursor: pointer; }
    .db-table__delete:hover {
      text-decoration: underline; }
  .db-table td {
    padding: 1.25rem;
    font-size: 1rem; }
  .db-table thead td {
    padding-top: 0;
    padding-bottom: 0.75rem; }
  .db-table--small td {
    padding: 1rem;
    font-size: 0.875rem; }
    @media screen and (max-width: 960px) {
      .db-table--small td {
        padding: 0.75rem 0.5rem; } }
  @media screen and (max-width: 600px) {
    .db-table td {
      padding: 1rem 0.5rem;
      font-size: 0.875rem; } }
  .db-table__check {
    width: 100px; }
    .db-table__check .custom-checkbox__wrap {
      margin: -0.5rem; }
  .db-table__tshirt-size {
    width: 125px; }
  .db-table__date-small {
    width: 120px; }
  .db-table tr td:last-child, .db-table .db-table__row td:last-child {
    flex-grow: 1;
    width: auto; }

.db-card {
  border: 1px solid #F2F9FF;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 15px 30px 0px rgba(65, 95, 185, 0.18);
  padding: 2rem;
  width: calc(100%/3 - 1.5rem);
  max-width: 350px;
  cursor: pointer;
  transform: scale(1);
  transition: .2s all ease-in-out; }
  .db-card__title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600; }
  .db-card__year {
    font-weight: 400; }
  .db-card__amount {
    margin: 0.5rem 0 0 0; }
  .db-card:hover {
    transform: scale(1.02); }
  @media screen and (max-width: 1280px) {
    .db-card {
      width: calc(100%/2 - 1.5rem);
      max-width: unset; } }
  @media screen and (max-width: 960px) {
    .db-card {
      width: 100%;
      padding: 1.25rem; } }

.db-search {
  padding: 1.5rem 0; }
  .db-search__inner {
    position: relative; }
  .db-search__input {
    background: #F4F4F4;
    width: 100%;
    padding: 1rem;
    -webkit-appearance: none;
    border: none;
    line-height: 1;
    font-size: 1rem;
    border-radius: 0.5rem; }
  .db-search__clear {
    position: absolute;
    right: 0;
    height: 100%;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    cursor: pointer; }
    .db-search__clear fa-icon {
      background: #DADADA;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #2E2E2E; }
  @media screen and (max-width: 960px) {
    .db-search {
      padding: 1.25rem 0; } }

.db-external-links {
  display: flex;
  overflow-y: scroll;
  padding: 1rem 0;
  margin: -1.5rem -0.25rem; }
  .db-external-links__item {
    list-style: none;
    display: flex; }
  .db-external-links__image {
    height: 1rem;
    max-width: 1.75rem;
    margin-right: 0.5rem;
    object-fit: contain; }
  .db-external-links__label {
    white-space: nowrap; }
  .db-external-links__link {
    border: 1px solid #E9E9E9;
    border-radius: 0.25rem;
    padding: 1rem;
    margin: 0.25rem;
    text-decoration: none;
    color: #1C1C1C;
    display: flex;
    align-items: center; }
    .db-external-links__link:hover {
      background: #F4F4F4;
      border-color: #A7A7A7; }

.db-year-select .mat-form-field-appearance-fill .mat-form-field-flex {
  padding-top: 0; }

.db-year-select .mat-form-field-infix {
  width: 120px;
  padding-top: 0; }

.db-year-select .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  top: 50%;
  position: relative;
  transform: none; }

.db-year-select .mat-form-field-underline {
  bottom: 0; }

.db-year-select .mat-form-field-wrapper {
  padding-bottom: 0; }

@media screen and (max-width: 960px) {
  .db-year-select .mat-form-field {
    width: 100%;
    margin: 0;
    margin-top: 0.5rem; } }

.db-settings {
  padding: 1rem 0;
  display: flex;
  flex-direction: column; }
  .db-settings .db-settings-item {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0; }
    .db-settings .db-settings-item__name {
      color: #333; }
    .db-settings .db-settings-item + .db-settings-item {
      border-top: 1px solid #E9E9E9; }

.swal-modal {
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }

.swal-button--delete {
  background: #f44336; }
  .swal-button--delete:not([disabled]):hover {
    background: #f22314; }

.dashboard {
  margin-top: 0;
  display: flex;
  flex-direction: column;
  font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }
  .dashboard__header {
    border-bottom: 1px solid #E9E9E9;
    padding: 0 2rem 0 2.5rem;
    margin-top: 1.25rem;
    display: flex;
    flex-direction: column; }
    .dashboard__header--no-breadcrumbs {
      padding-top: 1rem; }
    @media screen and (max-width: 960px) {
      .dashboard__header {
        margin-top: 1rem;
        padding: 0 1rem 0 1.5rem; } }
  .dashboard__action-items {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end; }
    @media screen and (max-width: 960px) {
      .dashboard__action-items {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        margin-top: 0.75rem; }
        .dashboard__action-items > * {
          width: 100%; } }
  .dashboard__title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    padding-right: 1rem; }
    @media screen and (max-width: 960px) {
      .dashboard__title {
        font-size: 1.5rem; } }
    .dashboard__title-line {
      display: flex;
      flex-direction: row;
      margin-top: 0;
      margin-bottom: 1.25rem; }
      @media screen and (max-width: 960px) {
        .dashboard__title-line {
          flex-direction: column; } }
  .dashboard__body {
    padding: 0 2rem 0 2.5rem;
    display: flex;
    flex-direction: column; }
    @media screen and (max-width: 960px) {
      .dashboard__body {
        padding: 0 1rem 0 1.5rem; } }
  .dashboard-section {
    padding: 2rem 0 2.5rem;
    display: flex;
    flex-direction: column; }
    .dashboard-section__title {
      margin: 0 0 1.25rem 0;
      font-size: 1.125rem;
      font-weight: 400;
      color: #626262;
      display: flex;
      align-items: center; }
    .dashboard-section__content {
      display: flex;
      width: 100%;
      gap: 1.5rem;
      flex-wrap: wrap; }
    .dashboard-section__empty {
      display: flex;
      justify-content: center;
      color: #C3C3C3;
      width: 100%;
      margin-top: 1rem; }
    .dashboard-section + .dashboard-section {
      border-top: 1px solid #E9E9E9; }
    .dashboard-section--active-signups .dashboard-section__title::before, .dashboard-section--other-signups .dashboard-section__title::before {
      content: '';
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      margin-right: 0.75rem;
      flex-shrink: 0; }
    .dashboard-section--active-signups .dashboard-section__title::before {
      background: lime; }
    .dashboard-section--other-signups .dashboard-section__title::before {
      background: orange; }

.rate-selection {
  padding: 2rem 1.75rem; }
  .rate-selection .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: #6EC761; }
  .rate-selection .mat-radio-button.mat-primary .mat-radio-inner-circle {
    color: #6EC761;
    background-color: #6EC761; }
  .rate-selection .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element {
    background-color: #7fce74; }
  .rate-selection__title {
    padding-top: 0 !important; }
  .rate-selection .mat-radio-group {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem; }
    @media screen and (max-width: 960px) {
      .rate-selection .mat-radio-group {
        flex-direction: column;
        gap: 1.25rem; } }
  .rate-selection__item {
    padding: 2rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid #E9E9E9;
    flex-grow: 1;
    flex-basis: 0;
    font-family: "Rubik", "Helvetica Neue", Helvetica, sans-serif; }
    .rate-selection__item.mat-radio-checked {
      border: 1px solid #6EC761;
      outline: 1px solid #6EC761;
      box-shadow: 0px 15px 30px 0px rgba(65, 95, 185, 0.18); }
    .rate-selection__item-description {
      font-size: 1.125rem;
      margin: 0;
      line-height: 1.2;
      font-weight: 400; }
    .rate-selection__item-price {
      font-weight: 600;
      font-size: 2rem;
      margin: 0;
      line-height: 1.2;
      color: #1C1C1C; }
    .rate-selection__item .mat-radio-label-content {
      padding-left: 1rem; }
    .rate-selection__item-content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      position: relative; }
    .rate-selection__item-suggested {
      background-color: #e7f6e5;
      color: #3f8f33;
      padding: 0.5rem 1.25rem;
      border-radius: 1.5rem;
      position: absolute;
      left: 0;
      font-size: 0.875rem;
      top: -3rem; }

.event-form button.submit-form {
  background-color: #34424C;
  font-size: 1rem; }
  .event-form button.submit-form .hidden-text {
    visibility: hidden; }
  .event-form button.submit-form .mat-button-wrapper {
    position: relative; }

.event-form .mat-card {
  padding: 2rem 1.75rem; }

.event-form .mat-card--payment.small {
  padding: 15px 24px; }
  .event-form .mat-card--payment.small h2 {
    font-size: 16px; }

.event-form .mat-card--payment.large {
  padding: 2.5rem 24px; }
  .event-form .mat-card--payment.large h2 {
    margin-bottom: 5px; }
  .event-form .mat-card--payment.large p {
    margin-top: 0; }

.event-form .countdown {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #333; }
  .event-form .countdown .countdown__item {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center; }
  .event-form .countdown .countdown__number {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0; }
  .event-form .countdown .countdown__text {
    font-size: 1.125rem;
    font-weight: 500; }

.event-form .card--top {
  padding: 2rem; }
  @media screen and (max-width: 600px) {
    .event-form .card--top {
      padding: 2rem 1.5rem; } }
  .event-form .card--top.success h1 {
    padding-bottom: 10px;
    margin-bottom: 0; }
  .event-form .card--top.success h2 {
    padding-top: 0;
    padding-left: 0;
    font-weight: normal;
    margin-top: 0; }
  .event-form .card--top.success img {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .event-form .card--top.success .success-card h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    color: #555; }
  .event-form .card--top.success .success-card p {
    font-size: 1.25rem;
    margin-bottom: 0;
    margin-top: 0.25rem; }
  .event-form .card--top.success .order-details {
    margin-bottom: 1rem; }
  .event-form .card--top.success .need-help {
    background: #fff9f5;
    border: 1px solid #feebdc;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem; }
  .event-form .card--top h2 {
    line-height: 35px; }
    @media screen and (max-width: 600px) {
      .event-form .card--top h2 {
        font-size: 16px;
        line-height: 24px; } }

.event-form .section__title {
  margin-bottom: 0.25rem; }

.event-form .section__subtitle {
  font-weight: 400;
  margin-top: 0;
  color: #737373;
  margin-top: 0.5rem; }

.event-form__logo {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  display: block; }
  @media screen and (max-width: 600px) {
    .event-form__logo {
      width: 170px; } }

.event-form__usps {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; }

.event-form__usp {
  width: calc(50% - 0.5rem);
  display: flex;
  align-items: center;
  background: #fff9f5;
  border-radius: 0.5rem;
  padding: 1.5rem 2rem;
  flex-grow: 1;
  border: 1px solid #feebdc; }
  @media screen and (max-width: 600px) {
    .event-form__usp {
      width: 100%;
      padding: 1.5rem; } }
  .event-form__usp-icon {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    padding-right: 1.5rem; }
    .event-form__usp-icon img {
      width: 100%;
      height: 100%; }
    @media screen and (max-width: 600px) {
      .event-form__usp-icon {
        width: 50px;
        padding-right: 1.25rem; } }
  .event-form__usp-text {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    color: #333; }
    .event-form__usp-text span:first-child {
      font-weight: 400;
      font-size: 1rem;
      margin-bottom: 0.25rem; }
    .event-form__usp-text span:nth-child(2) {
      font-weight: 600;
      font-size: 1.6rem; }
    .event-form__usp-text span:nth-child(3) {
      font-weight: 500;
      font-size: 1.125rem;
      margin-bottom: 0.25rem; }

.loader {
  width: 22px;
  height: 22px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: -1px;
  left: 50%;
  translate: -50%; }

@keyframes rotation {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.food-item {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 600px) {
    .food-item {
      flex-direction: column;
      margin-bottom: 2rem; } }
  @media screen and (max-width: 600px) {
    .food-item-overview > *:not(:last-child) .food-item {
      border-bottom: 1px solid #E9E9E9;
      padding-bottom: 2rem; } }
  .food-item__image {
    width: 200px;
    border-radius: 5px;
    height: auto;
    object-fit: contain;
    margin: 8px 0;
    display: block; }
  .food-item__title {
    text-align: center; }
    .food-item__title h3 {
      font-size: 23px;
      font-weight: 300;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap; }
      .food-item__title h3 .category-label {
        display: inline-flex;
        align-items: center;
        padding: 2px 8px;
        border-radius: 12px;
        font-size: 0.8rem;
        font-weight: 500; }
        .food-item__title h3 .category-label fa-icon {
          margin-right: 4px;
          font-size: 0.8rem; }
        .food-item__title h3 .category-label.fish {
          background-color: #e3f2fd;
          color: #0277bd; }
        .food-item__title h3 .category-label.vegetarian {
          background-color: #e8f5e9;
          color: #2e7d32; }
    .food-item__title p {
      margin-top: 0;
      color: #888;
      font-size: 14px; }
  .food-item__count {
    display: flex;
    align-items: center;
    justify-content: center; }
  .food-item__number {
    font-size: 22px;
    width: 80px;
    text-align: center;
    font-weight: 600; }
    .food-item__number fa-icon {
      font-size: 15px;
      pointer-events: none; }
  .food-item__less, .food-item__more {
    width: 45px;
    height: 45px;
    background: #34424C;
    color: white;
    padding: 9px 11px;
    border-radius: 0.5rem;
    cursor: pointer;
    transform: scale(1);
    transition: .1s all ease-in-out;
    box-shadow: 2px 2px 3px -1px rgba(0, 0, 0, 0.2);
    display: grid;
    place-content: center;
    touch-action: manipulation; }
    .food-item__less:hover, .food-item__more:hover {
      transform: scale(1.1);
      background: #2a353d; }
    .food-item__less.disabled, .food-item__more.disabled {
      pointer-events: none;
      background: #eee;
      color: #bbb;
      box-shadow: none; }
    .food-item__less fa-icon, .food-item__more fa-icon {
      pointer-events: none; }
  @media screen and (min-width: 600px) {
    .food-item__title {
      width: 100%;
      padding-left: 2rem;
      text-align: left; } }

.event-form form {
  padding: 0.5rem 0; }
  .event-form form .form-field {
    display: flex; }
    @media screen and (max-width: 600px) {
      .event-form form .form-field {
        flex-direction: column; }
        .event-form form .form-field__label {
          font-size: 1rem;
          margin: 0; }
        .event-form form .form-field__input, .event-form form .form-field .radio-button-group {
          width: 100%;
          margin-inline: 0; } }

.event-form .form-field {
  display: flex;
  margin-bottom: 15px;
  align-items: flex-start; }
  .event-form .form-field .mat-form-field-infix {
    border-top: 0.25rem solid transparent; }
  .event-form .form-field__label-group {
    flex-grow: 1;
    margin-inline: 10px; }
  .event-form .form-field__label {
    margin: 0 0 5px 0;
    font-weight: 500;
    font-weight: 600;
    font-size: 1.125rem;
    flex-grow: 1; }
    .event-form .form-field__label .optional {
      font-size: 0.75rem;
      color: #626262;
      font-weight: 400;
      font-style: italic; }
  .event-form .form-field__description {
    font-size: 0.875rem;
    color: #626262;
    margin: 0; }
  .event-form .form-field__input {
    width: 65%;
    flex-shrink: 0; }

.event-form .radio-button-group {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 20px;
  width: 65%;
  flex-shrink: 0; }
  .event-form .radio-button-group .mat-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%; }
  .event-form .radio-button-group .mat-radio-button {
    background-color: #fff;
    border: 1px solid #DADADA;
    border-radius: 7px;
    min-width: 60px;
    text-align: center;
    flex: 1; }
    .event-form .radio-button-group .mat-radio-button .mat-radio-label {
      width: 100%;
      justify-content: center;
      padding: 10px 0; }
      .event-form .radio-button-group .mat-radio-button .mat-radio-label-content {
        padding: 0; }
    .event-form .radio-button-group .mat-radio-button .mat-radio-container {
      display: none; }
    .event-form .radio-button-group .mat-radio-button.mat-radio-checked {
      background-color: rgba(52, 66, 76, 0.1);
      border: 1px solid #34424C;
      box-shadow: 0 0 0 1px #34424C;
      color: #2E2E2E; }
  .event-form .radio-button-group.time-slots .mat-radio-button {
    flex: 1; }
  .event-form .radio-button-group.time-slots .timeslot-full {
    display: inline-block;
    font-size: 0.75rem;
    color: #f44336;
    margin-left: 5px;
    font-weight: 500; }
  .event-form .radio-button-group.time-slots .timeslot-full-message {
    margin-top: 10px;
    padding: 10px;
    background-color: rgba(244, 67, 54, 0.1);
    border: 1px solid #f44336;
    border-radius: 7px;
    color: #f44336;
    font-size: 0.875rem; }
  .event-form .radio-button-group .radio-error {
    font-size: 0.75rem;
    margin-top: 4px;
    color: #f44336; }

.event-form .section__title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #2E2E2E; }

.event-form .section__subtitle {
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #626262; }

.event-form .debug-button {
  margin-top: 15px;
  background-color: rgba(52, 66, 76, 0.1);
  border: 1px dashed #34424C; }
